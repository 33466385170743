
import React, { useEffect, useState } from 'react';
import {auth , provider}  from '../firebase.js';
import { useNavigate } from 'react-router-dom';
import subject from '../myservice.js';
  
const Login = () => {

    function sentData(data, uid) {
        fetch("https://otpapp-react-default-rtdb.europe-west1.firebasedatabase.app/accounts/"+uid+".json", {
          method: "PUT",
          body: JSON.stringify(data),
          headers: {
            "Content-type": "text/plain",
          },
        }).then((response) => response.json()).then((data) => {
            console.log(data);
            // Handle data
        }).catch((err) => {
            console.log(err.message);
        });
      }

  const navigate = useNavigate();
  const [logged, setLogged] = useState(null);

  useEffect(() => {
      //navigate("/panel");
  }, [logged]);
 
    // Sign in with google
    const signin = () => {
      auth.signInWithPopup(provider).catch(alert).then((d)=>{
        console.log(d.user.uid);
        //console.log(d.user.email);
        //console.log(d.user.refreshToken);
        localStorage.setItem("token", d.user.refreshToken);
        localStorage.setItem("uid", d.user.uid);
        localStorage.setItem("email", d.user.email);
        localStorage.setItem("name", d.user.displayName);
        subject.next(d.user.refreshToken);
        sentData({
            "name": d.user.displayName,
            "email": d.user.email
        }, d.user.uid);
        navigate("/panel");
        //setLogged(d.user.refreshToken);
       });
    }
      
    return (
        <div>
            <center>
            <button className='btn btn-primary' style={{"marginTop" : "20px", "marginBottom": "20px"}} 
                onClick={signin}>Sign In with Google</button>
                <img src="ottbanner.jpg" alt="banner" style={{width:'100%'}}/>
            </center>
        </div>
    );
}
  
export default Login;