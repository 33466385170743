import { Link } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import { useEffect, useState } from "react";

function Panel() {

  function sentData(data) {
    fetch("https://otpapp-react-default-rtdb.europe-west1.firebasedatabase.app/transaction.json", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "text/plain",
      },
    }).then((response) => response.json()).then((data) => {
        console.log(data);
        // Handle data
    }).catch((err) => {
        console.log(err.message);
    });
  }
 

  const [showTrans, setTrans] = useState("");
  const [showAddMoney, setAddMoney] = useState(false);
  const [amount, setAmount] = useState(0);
  const [tid, setTID] = useState("");
  const [tamount, setTAmount] = useState(0);
  const [tmsg, setTMsg] = useState('');

  const [accountData, setAccountData] = useState({});
  //let showTransaction = false;

  function showAlert() {
    let uid = localStorage.getItem("uid") ?? null;
    if (tid.length == 12 && tamount >= 100) {
      //setAmount(200);
      //localStorage.setItem("amount", 200);
      setTrans("success");
      setTMsg("Transaction ID successfully added wait for few minutes");
      sentData({
        "uid": uid,
        "amount": tamount,
        "tid": tid
      })
    } else {
      setTrans("failed");
      setTMsg("Invalid Transction ID or Transaction amount is less than Rs.100, please check and try again");
    }

    setTimeout(() => {
      setTrans("");
    }, 5000);
  }

  function showAddMoneyDiv() {
    setAddMoney(!showAddMoney);
  }

  function getBalance(){
    let uid = localStorage.getItem("uid") ?? null;
    fetch("https://otpapp-react-default-rtdb.europe-west1.firebasedatabase.app/accounts/"+uid+".json", {
      method: "GET",
      //body: JSON.stringify(data),
      headers: {
        "Content-type": "text/plain",
      },
    }).then((response) => response.json()).then((data) => {
        console.log(data);
        setAccountData(data);
        // Handle data
    }).catch((err) => {
        console.log(err.message);
    });
  }

  useEffect(()=>{
    getBalance();
  }, [])
  

  const name = localStorage.getItem("name");
  const email = localStorage.getItem("email");

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 text-center">
          <div className="shadow p-4 rounded mt-5">
            <h1>Panel</h1>
            <p>Current Balance Rs.{accountData?.balance??0}</p>
            <p>
              <strong>
                {name} ({email})
              </strong>
            </p>
            <button className="btn btn-primary" onClick={showAddMoneyDiv}>
              Add Money
            </button>
            <Link className="myLink" to="/transactions">
              <button className="btn btn-primary">Transactions</button>
            </Link>
            {showAddMoney == true ? (
              <div className="row mt-5 p-4 border">
                <div className="col-md-6 text-center">
                  <h3>Scan QR and Pay</h3>
                  <p style={{ color: "red" }}>
                    Don't forget to add your email id in payment note
                  </p>
                  <img
                    src="qrcode.png"
                    style={{ width: "300px" }}
                    alt="qr code"
                  />
                  <p>
                    <small style={{ color: "green" }}>
                      Minimum Transaction Limit is Rs. 100
                    </small>
                  </p>
                  <p>
                    <small>
                      Transaction related issues contact us from support.
                    </small>
                  </p>
                </div>
                <div className="col-md-6 text-start">
                  <p>
                    After successful payment enter transaction id and submit
                  </p>
                  <input
                    type="text"
                    name="tid"
                    placeholder="Enter Transaction Amount"
                    onInput={(e) => setTAmount(e.target.value)}
                    className="form-control"
                    required
                  />
                  <input
                    type="text"
                    name="tid"
                    placeholder="Enter Transaction ID"
                    onInput={(e) => setTID(e.target.value)}
                    className="form-control"
                    required
                  />
                  <button className="btn btn-primary" onClick={showAlert}>
                    Submit
                  </button>
                  <p className="pt-3">
                    Call Us for Payment related query:{" "}
                    <a
                      className="btn btn-outline-primary"
                      href="tel:+918210526671"
                    >
                      +918210526671
                    </a>
                  </p>
                  {showTrans == "success" ? (
                    <Alert key={"success"} variant={"success"}>
                      {tmsg}
                    </Alert>
                  ) : (
                    ""
                  )}
                  {showTrans == "failed" ? (
                    <Alert key={"danger"} variant={"danger"}>
                      {tmsg}
                    </Alert>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Panel;
