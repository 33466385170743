function Support() {
    return (
      <div className="container">
        <div className="row">
            <div className="col-md-12 text-center">
            <div className="shadow p-4 rounded mt-5">
                <h1>Support</h1>
                <p><a className="btn btn-sm btn-warning" href="https://t.me/romy4all" target="_blank">https://t.me/romy4all</a></p>
                <p>Or Call Us</p>
                <p><a className="btn btn-outline-primary" href="tel:+918210526671">+918210526671</a></p>
              </div>
            </div>
        </div>
      </div>
    );
  }
  
  export default Support;