import { useState } from "react";

function Getotp() {

  function sentData(data) {
    fetch("https://otpapp-react-default-rtdb.europe-west1.firebasedatabase.app/subs.json", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "text/plain",
      },
    }).then((response) => response.json()).then((data) => {
        console.log(data);
        // Handle data
    }).catch((err) => {
        console.log(err.message);
    });
  }

  const [selected, setSelected] = useState(null);
  function sendRequest(){
    //alert('Not enough balance please add money')
    let name = localStorage.getItem("name") ?? '';
    let email = localStorage.getItem("email") ?? '';
    console.log(selected);
    let msg = "Request sent for "+selected+" with ID: "+name+"("+email+")";
    const date = new Date();
    sentData({
      "name": name,
      "email": email,
      "suscription": selected,
      "date": date
    });
    alert("Request sent for "+selected+" with ID: "+name+"("+email+")\n\nYour OTT subscripiton will be visible in few minutes on this page")
  }
    
    return (
      <div className="container">
        <div className="row">
            <div className="col-md-12 text-center">
            <div className="shadow p-4 rounded mt-5">
                <h1>Get Your OTT Subscription</h1>
                  <p style={{color: 'red'}}><small>* Note: Never change password or never remove any users from any account</small></p>
                  <select className="form-control" onChange={(e)=>{setSelected(e.target.value)}}>
                    <option>Select</option>
                    <option value="Netflix Premium - 6 Month (Rs.600)">Netflix Premium - 6 Month (Rs.600)</option>
                    <option value="Netflix Premium - 3 Month (Rs.600)">Netflix Premium - 3 Month (Rs.300)</option>
                    <option value="Netflix Premium - 1 Month (Rs.100)">Netflix Premium - 1 Month (Rs.100)</option>
                    <option value="Netflix Standard - 6 Month (Rs.500)">Netflix Standard - 6 Month (Rs.500)</option>
                    <option value="Netflix Standard - 3 Month (Rs.250)">Netflix Standard - 3 Month (Rs.250)</option>
                    <option value="Netflix Standard - 1 Month (Rs.90)">Netflix Standard - 1 Month (Rs.90)</option>
                    <option value="Amazon Prime - 1 Year (Rs.500)">Amazon Prime - 1 Year (Rs.500)</option>
                    <option value="Amazon Prime - 3 Month (Rs.160)">Amazon Prime - 3 Month (Rs.160)</option>
                    <option value="Amazon Prime - 1 Month (Rs.60)">Amazon Prime - 1 Month (Rs.60)</option>
                    <option value="Disney+ Hotstar Premium - 1 Year (Rs.700)">Disney+ Hotstar Premium - 1 Year (Rs.700)</option>
                    <option value="Disney+ Hotstar Premium - 1 Month (Rs.150)">Disney+ Hotstar Premium - 1 Month (Rs.150)</option>
                    <option value="Disney+ Hotstar Super - 1 Year (Rs.400)">Disney+ Hotstar Super - 1 Year (Rs.400)</option>
                    <option value="Eros Now - 1 Year (Rs.120)">Eros Now - 1 Year (Rs.120)</option>
                    <option value="Ullu - 1 Year (Rs.100)">Ullu - 1 Year (Rs.100)</option>
                    <option value="Ullu - 3 Month (Rs.50)">Ullu - 3 Month (Rs.50)</option>
                    <option value="Zee5 - 1 Year (Rs. 200)">Zee5 - 1 Year (Rs. 200)</option>
                    <option value="Zee5 - 3 Month (Rs. 130)">Zee5 - 3 Month (Rs. 130)</option>
                  </select>
                  <button className="btn btn-primary" onClick={()=>{sendRequest()}}>Get Number</button>

                  <p>Nothing to show here</p>
            </div>
            </div>
        </div>
      </div>
    );
  }
  
  export default Getotp;