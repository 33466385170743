import "./App.css";
import Header from "./components/Header";
import Login from "./components/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Panel from "./components/Panel";
import Home from "./components/Home";
import Getott from "./components/Getott";
import Recharge from "./components/Reacharge";
import Transactions from "./components/Transactions";
import Support from "./components/Support";

import {auth} from './firebase.js';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/panel" element={<Panel />}></Route>
          <Route path="/recharge" element={<Recharge />}></Route>
          <Route path="/getott" element={<Getott />}></Route>
          <Route path="/transactions" element={<Transactions />}></Route>
          <Route path="/support" element={<Support />}></Route>
        </Routes>
        <p className="text-center mt-4">(C) 2023 All Rights Reserved</p>
      </BrowserRouter>
    </div>
  );
}

export default App;
